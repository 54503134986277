import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

import { CustomFirestoreState } from './types';

export const initialState: CustomFirestoreState = {
  // @ts-ignore
  data: {},
  status: { requesting: {} },
};

const slice = createSlice({
  name: 'customFirestore',
  initialState,
  reducers: {
    updateWithFirestoreResult(state, action: PayloadAction<any>) {
      return {
        // that has all the existing state data
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.result,
        },
      };
    },
    updateFirestoreRequestingStatus(state, action: PayloadAction<any>) {
      return {
        // that has all the existing state data
        ...state,
        status: {
          ...state.status,
          requesting: {
            ...state.status.requesting,
            [action.payload.name]: action.payload.status,
          },
        },
      };
    },
    clearData(state, action: PayloadAction<any>) {
      return initialState;
    },
  },
});

export const { actions: customFirestoreActions } = slice;

export const useCustomFirestoreSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
