import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

// Your web app's Firebase configuration
let firebaseConfig;
let firebaseGeoipConfig;
if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyBeCBAUkWbgtLWvj4Gx_AI16MXs5pQ6yjM',
    authDomain: 'docugraph.firebaseapp.com',
    projectId: 'docugraph',
    storageBucket: 'docugraph.appspot.com',
    messagingSenderId: '566225345680',
    appId: '1:566225345680:web:877aa6e763f6562d5b4c15',
  };
  firebaseGeoipConfig = {
    apiKey: 'AIzaSyCUPowl5tqSc9-TNXikrZUqO_iLI3F0pKQ',
    authDomain: 'docugraph-geoip.firebaseapp.com',
    projectId: 'docugraph-geoip',
    storageBucket: 'docugraph-geoip.appspot.com',
    messagingSenderId: '203498742455',
    appId: '1:203498742455:web:35ccf7a4d256dd5b7f2332',
    measurementId: 'G-6KLKJ9CFDP',
  };
} else if (
  ['staging', 'development'].includes(process.env.REACT_APP_CUSTOM_NODE_ENV!)
) {
  firebaseConfig = {
    apiKey: 'AIzaSyA3faOBT59ilFEEPnp4rAHaMRgZnqJxkCM',
    authDomain: 'docugraph-staging.firebaseapp.com',
    projectId: 'docugraph-staging',
    storageBucket: 'docugraph-staging.appspot.com',
    messagingSenderId: '321109633575',
    appId: '1:321109633575:web:ea94435198ebb7138b3c02',
    measurementId: 'G-SSQY73Z975',
  };
  firebaseGeoipConfig = {
    apiKey: 'AIzaSyBNt3zZTv0oemZlhdFHWTL25QFXHdk8Hd4',
    authDomain: 'docugraph-geoip-staging.firebaseapp.com',
    projectId: 'docugraph-geoip-staging',
    storageBucket: 'docugraph-geoip-staging.appspot.com',
    messagingSenderId: '308210704409',
    appId: '1:308210704409:web:1a71d1d0d5402922610a88',
    measurementId: 'G-8XDNW9P8W7',
  };
}

// Initialize Firebase
export const firebaseDefault = firebase.initializeApp(firebaseConfig);
export const firebaseGeoip = firebase.initializeApp(
  firebaseGeoipConfig,
  'docugraph-geoip',
);
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

// Connect to Firebase emulators
if (process.env.NODE_ENV === 'development') {
  auth.useEmulator('http://localhost:9099');
  db.useEmulator('localhost', 8080);
  storage.useEmulator('localhost', 9199);
  functions.useEmulator('localhost', 5001);
}
