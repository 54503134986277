import { useEffect, useState, useMemo, useCallback } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';

import { useAPIAuthActions } from 'app/api/auth';
import { useAPISelectProfileClaims } from 'app/api/user';
import { useCustomFirestoreSlice } from 'app/slices/CustomFirestoreSlice';
import { useFunctionsSlice } from 'app/slices/FunctionsSlice';

import { RootState } from 'types/RootState';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

/* Gets the current Firebase user */
export const getCurrentUser = (): any => {
  return firebase.auth().currentUser;
};

/* Generates a random Firebase ID */
export const autoId = (): string => {
  const CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let autoId = '';

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

/* Generates Firestore join ID */
export const join = (a, b) => `${a}:_+_:${b}`;

/* Wraps a new object */
export const newObject = (obj = {}) => {
  return Object.assign(
    {
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    },
    obj,
  );
};

/* Wraps an updated object */
export const updatedObject = (obj = {}) => {
  return Object.assign(
    {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    obj,
  );
};

/* Creates a unclaimed object */
export const unclaimedObject = (obj = {}) => {
  return Object.assign(
    {
      unclaimed: true,
    },
    obj,
  );
};

/* Wraps a new object and claims it */
export const claimWithNewObject = (obj = {}) => {
  return Object.assign(
    {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      unclaimed: firebase.firestore.FieldValue.delete(),
    },
    obj,
  );
};

/* Firestore Populate Function (IDs -> Resources)  */
export const firestorePopulate = async (ids, firestoreQuery) => {
  const idChunks = _.chunk(ids, 10); // Firestore only allows 10 documents at a time
  const result = {};
  await Promise.all(
    idChunks.map(async idChunk => {
      const querySnapshot = await firestoreQuery
        .where(firebase.firestore.FieldPath.documentId(), 'in', idChunk)
        .get();
      querySnapshot.forEach(doc => {
        result[doc.id] = doc.data();
      });
    }),
  );
  return result;
};

/* Gets a dispatch function that is deferred */
export const useDeferredDispatch = () => {
  const dispatch = useDispatch();
  return useCallback(action => _.defer(() => dispatch(action)), [dispatch]);
};

/* Fetches the result of a Firebase Functions  */
export const _useFunction = (firebase, name, functionName, ...args) => {
  const dispatch = useDeferredDispatch();
  const { actions } = useFunctionsSlice();
  const dependencies = [
    dispatch,
    actions,
    name,
    functionName,
    JSON.stringify(args),
  ];
  useMemo(async () => {
    const firebaseFunction = firebase.functions().httpsCallable(functionName);
    dispatch(
      actions.updateFunctionRequestingStatus({
        name: name,
        status: true,
      }),
    );
    try {
      const functionResult = await firebaseFunction({
        args: args,
      });
      dispatch(
        actions.updateWithFunctionResult({
          name: name,
          result: functionResult.data,
        }),
      );
    } catch (e) {
      console.log('error', e);
      // Ignore
    } finally {
      dispatch(
        actions.updateFunctionRequestingStatus({
          name: name,
          status: false,
        }),
      );
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

/* Fetches the result of a Firebase Functions  */
export const useFunction = (name, functionName, ...args) => {
  return _useFunction(firebase, name, functionName, ...args);
};

/* Populates via Firebase Functions  */
export const usePopulateWithFunction = (
  isLoading,
  name,
  populateFunctionName,
  populateFunctionArgs,
  ...args
) => {
  const dispatch = useDeferredDispatch();
  const { actions } = useFunctionsSlice();
  const dependencies = [
    dispatch,
    actions,
    isLoading,
    name,
    populateFunctionName,
    JSON.stringify(args),
  ];
  useMemo(async () => {
    const populateFunction = firebase
      .functions()
      .httpsCallable(populateFunctionName);
    try {
      dispatch(
        actions.updateFunctionRequestingStatus({
          name: name,
          status: true,
        }),
      );
      const newArgs = populateFunctionArgs(...args);
      if (newArgs) {
        const populateFunctionResult = await populateFunction({
          args: newArgs,
        });
        dispatch(
          actions.updateWithFunctionResult({
            name: name,
            result: populateFunctionResult.data,
          }),
        );
      }
    } catch (e) {
      // Ignore
    } finally {
      dispatch(
        actions.updateFunctionRequestingStatus({
          name: name,
          status: isLoading,
        }),
      );
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

/* Populates via Custom Firebase Firestore  */
export const usePopulateWithCustomFirestore = (
  isLoading,
  name,
  populatedDataFunction,
  ...args
) => {
  const dispatch = useDeferredDispatch();
  const { actions } = useCustomFirestoreSlice();
  useMemo(async () => {
    dispatch(
      actions.updateFirestoreRequestingStatus({
        name: name,
        status: true,
      }),
    );
    try {
      const populateFunctionResult = await populatedDataFunction(...args);
      dispatch(
        actions.updateWithFirestoreResult({
          name: name,
          result: populateFunctionResult,
        }),
      );
    } finally {
      dispatch(
        actions.updateFirestoreRequestingStatus({
          name: name,
          status: isLoading,
        }),
      );
    }
  }, [dispatch, actions, isLoading, name, JSON.stringify(args)]); // eslint-disable-line react-hooks/exhaustive-deps
};

/* Returns data from Firebase Firestore and the loading status */
export const useFirestoreSelector = (selector, defaultValue: any = null) => {
  const data = useSelector((state: RootState) =>
    selector(state.firestore.data),
  );
  const isLoading = useSelector((state: RootState) =>
    _.defaultTo(selector(state.firestore.status.requesting), true),
  );
  return [!isLoaded(data) || isEmpty(data) ? defaultValue : data, isLoading];
};

/* Returns data from Custom Firebase Firestore and the loading status */
export const useCustomFirestoreSelector = selector => {
  const data = useSelector((state: RootState) =>
    selector(state.customFirestore.data),
  );
  const isLoading = useSelector((state: RootState) =>
    _.defaultTo(selector(state.customFirestore.status.requesting), true),
  );
  return [data, isLoading];
};

/* Returns data from Firebase Functions and the loading status */
export const useFunctionsSelector = selector => {
  const data = useSelector((state: RootState) =>
    selector(state.functions.data),
  );
  const isLoading = useSelector((state: RootState) =>
    _.defaultTo(selector(state.functions.status.requesting), true),
  );
  return [data, isLoading];
};

/* A file storage upload hook [isLoading, {finished, progress (0-1), error, cancel, pause, resume}] */
export const useStorageUpload = (
  claimFuncName,
  claimKey,
  claimValue,
  key,
  file,
  metadata,
) => {
  const [uploadedFile, setUploadedFile]: any = useState([true, {}]);
  const [readyToUpload, setReadyToUpload] = useState(false);
  const { updateIdToken } = useAPIAuthActions();

  // Get the user's claims
  const claims = useAPISelectProfileClaims();

  // Check if the claim is set
  useEffect(() => {
    setReadyToUpload(
      (claims[claimKey] === claimValue &&
        Date.now() - claims[`${claimKey}_time`] < 60 * 1000) ||
        readyToUpload,
    );
  }, [claims, claimKey, claimValue, readyToUpload, setReadyToUpload]);

  // Define set claim function
  const setClaim = useCallback(async () => {
    await firebase.functions().httpsCallable(claimFuncName)({
      claimValue,
    });
    await updateIdToken();
  }, [claimFuncName, claimValue]); // eslint-disable-line react-hooks/exhaustive-deps

  // Create an upload task
  useMemo(async () => {
    // Set isLoading to true
    setUploadedFile([true, {}]);

    // Get a reference to the file
    const fileRef = firebase.storage().ref().child(key);

    // Check if we are ready to upload
    if (readyToUpload) {
      // If so, upload the file
      const uploadTask = fileRef.put(file, metadata);
      setUploadedFile([
        true,
        {
          finished: false,
          progress: 0,
          error: null,
          cancel: () => uploadTask.cancel(),
          pause: () => uploadTask.pause(),
          resume: () => uploadTask.resume(),
        },
      ]);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function (
        snapshot,
      ) {
        const percent = snapshot.bytesTransferred / snapshot.totalBytes;
        setUploadedFile(uploadedFile => [
          true,
          Object.assign(uploadedFile[1], {
            finished: false,
            progress: percent,
            error: null,
          }),
        ]);
      });
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
        complete: function () {
          setUploadedFile(uploadedFile => [
            false,
            Object.assign(uploadedFile[1], {
              finished: true,
            }),
          ]);
        },
      });
      uploadTask.catch(error => {
        setUploadedFile(uploadedFile => [
          false,
          Object.assign(uploadedFile[1], {
            finished: true,
            error: error,
          }),
        ]);
      });
    } else {
      // Otherwise, set the user's claims
      try {
        await setClaim();
      } catch (e) {
        return setUploadedFile([false, {}]);
      }
    }
  }, [readyToUpload, key, file, JSON.stringify(metadata), setClaim]); // eslint-disable-line react-hooks/exhaustive-deps

  // Return the upload task
  return uploadedFile;
};

/* A file storage fetch hook [isLoading, fileExists, {metadata, downloadUrl}] */
export const useStorageFetch = (
  claimFuncName,
  claimKey,
  claimValue,
  key,
  refreshId = 'default',
) => {
  const isPrivateFile = claimFuncName || claimKey || claimValue;
  const [fetchedFile, setFetchedFile]: any = useState([true, null, {}]);
  const [readyToFetch, setReadyToFetch] = useState(!isPrivateFile);
  const { updateIdToken } = useAPIAuthActions();

  // Get the user's claims
  const claims = useAPISelectProfileClaims();

  // Check if the claim is set
  useEffect(() => {
    setReadyToFetch(
      (claims[claimKey] === claimValue &&
        Date.now() - claims[`${claimKey}_time`] < 60 * 1000) ||
        readyToFetch,
    );
  }, [claims, claimKey, claimValue, readyToFetch, setReadyToFetch]);

  // Reset readyToFetch when the refreshId is updated since claims have changed
  useEffect(() => {
    setReadyToFetch(!isPrivateFile);
  }, [refreshId, isPrivateFile, setReadyToFetch]);

  // Define set claim function
  const setClaim = useCallback(async () => {
    await firebase.functions().httpsCallable(claimFuncName)({
      claimValue,
    });
    await updateIdToken();
  }, [claimFuncName, claimValue]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch the file
  useMemo(async () => {
    // Set isLoading to true
    setFetchedFile([true, null, {}]);

    // Get a reference to the file
    const fileRef = firebase.storage().ref().child(key);

    // Check if we are ready to fetch
    if (readyToFetch) {
      try {
        // If so, fetch the file
        const [metadata, downloadUrl] = await Promise.all([
          fileRef.getMetadata(),
          fileRef.getDownloadURL(),
        ]);
        return setFetchedFile([false, true, { metadata, downloadUrl }]);
      } catch (e) {
        // If fetching failed, the file may not exist
        return setFetchedFile([false, false, {}]);
      }
    } else {
      // Otherwise, set the user's claims
      try {
        await setClaim();
      } catch (e) {
        return setFetchedFile([false, null, {}]);
      }
    }
  }, [readyToFetch, key, setClaim]);

  // Return the fetched file
  return fetchedFile;
};
