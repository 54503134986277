/**
 *
 * IntroProvider
 *
 */
import * as React from 'react';

import { get, every, isEmpty } from 'lodash';

import { useAPIAuthActions } from 'app/api/auth';
import { useAPISelectProfile } from 'app/api/user';

export const IntroContext: React.Context<any> = React.createContext(null);

interface Props {
  children: React.ReactNode;
}

/**
 * Generates the key for the hint in the user's profile.
 * @param  {string} name The name of the hint
 * @return {string}      The key where that hint acknowledgement is stored
 */
export const getHintKey = name => `intro__hints__${name}`;

/**
 * Generates the key for the step in the user's profile.
 * @param  {string} name The name of the step
 * @return {string}      The key where that step acknowledgement is stored
 */
export const getStepKey = name => `intro__steps__${name}`;

export function IntroProvider({ children }: Props) {
  const { updateProfile } = useAPIAuthActions();
  const profile = useAPISelectProfile();

  /**
   * If the user has disabled intro hints/steps for the future.
   * @type {boolean}
   */
  const areIntrosDisabled = get(profile, 'introDisabled', false);

  /**
   * Looks up a hint/step in the profile and returns whether or not
   * it has been acknowledged.
   * @param  {string} name The name of the hint/step to lookup
   * @return {boolean}     Whether or not the hint/step has been seen
   */
  const isIntroAcknowledged = name => get(profile, name, false);

  /**
   * Whether or not all the hint/step dependencies provided have been
   * acknowledged or not.
   * @param  {string[]} dependencies The names of the hints/steps
   * @return {boolean}               Whether or not they've all been viewed
   */
  const areDependenciesAcknowledged = dependencies =>
    isEmpty(dependencies) ||
    every(dependencies.map(d => isIntroAcknowledged(d)));

  /**
   * Determines if the hint should show, based on the dependencies that should
   * come before it, if hints are enabled, and if its already been seen.
   * @param  {string}   name         The name of the hint to check
   * @param  {string[]} dependencies The list of hints that should show before
   * @return {boolean}               Whether or not they have all been seen
   */
  const shouldShowIntro = (name, dependencies) =>
    !areIntrosDisabled &&
    !isIntroAcknowledged(name) &&
    areDependenciesAcknowledged(dependencies);

  /**
   * Acknowledges a hint so that the user doesn't see it again in the future.
   * @param  {string} name The name of the hint to mark as acknowledged
   * @return {void}        Acknowledges the hint
   */
  const acknowledgeIntro = async name => {
    updateProfile({
      [name]: true,
    });
  };

  /**
   * Disables hints for the user permanently.
   * @return {void} Disables hints
   */
  const disableIntros = async () => {
    updateProfile({
      introDisabled: true,
    });
  };

  return (
    <IntroContext.Provider
      value={{
        areIntrosDisabled,
        isIntroAcknowledged,
        areDependenciesAcknowledged,
        shouldShowIntro,
        acknowledgeIntro,
        disableIntros,
      }}
    >
      {children}
    </IntroContext.Provider>
  );
}
