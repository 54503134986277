import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { useAPISelectAuth } from 'app/api/auth';
import {
  getCurrentUser,
  join,
  updatedObject,
  useFirestoreSelector,
  useStorageUpload,
  useStorageFetch,
} from 'app/api/utils';

import { RootState } from 'types/RootState';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

export const acceptWorkspaceInvite = workspaceId => {
  const auth = getCurrentUser();
  return firebase
    .firestore()
    .collection('workspaceUsers')
    .doc(join(workspaceId, auth.uid))
    .update(
      updatedObject({
        pending: false,
        accepted: true,
      }),
    );
};

export const rejectWorkspaceInvite = workspaceId => {
  const auth = getCurrentUser();
  return firebase
    .firestore()
    .collection('workspaceUsers')
    .doc(join(workspaceId, auth.uid))
    .update(
      updatedObject({
        pending: false,
        accepted: false,
      }),
    );
};

export const leaveWorkspace = workspaceId => {
  const auth = getCurrentUser();
  return firebase.functions().httpsCallable('removeUserFromWorkspace')({
    userId: auth.uid,
    workspaceId: workspaceId,
  });
};

export const deleteUser = () => {
  return firebase.functions().httpsCallable('deleteUser')({});
};

export const useAPIListenUserWorkspaceInvites = () => {
  const auth = useAPISelectAuth();
  useFirestoreConnect([
    {
      collection: 'workspaceUsers',
      where: [
        ['user', '==', auth.uid],
        ['pending', '==', true],
      ],
      storeAs: 'userWorkspaceInvites',
    },
  ]);
};

export const useAPISelectProfile = () => {
  return useSelector((state: RootState) => state.firebase.profile);
};

export const useAPISelectProfileClaims = () => {
  return useSelector((state: RootState) =>
    get(state, 'firebase.profile.token.claims', {}),
  );
};

export const useAPISelectUserWorkspaceInvites = () => {
  return useFirestoreSelector(firestore => firestore.userWorkspaceInvites, []);
};

export const useAPIUploadProfilePhoto = (
  file: File | Blob | Uint8Array,
  metadata,
) => {
  const auth = getCurrentUser();
  const userId = auth.uid;
  return useStorageUpload(
    'setUserWriteClaim',
    'write_user',
    userId,
    `users/${userId}/profilePhoto`,
    file,
    metadata,
  );
};

export const useAPIFetchProfilePhoto = () => {
  const auth = getCurrentUser();
  const userId = auth.uid;
  return useStorageFetch(null, null, null, `users/${userId}/profilePhoto`);
};
