/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that provides to all pages.
 */

import * as React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConfigProvider, message, notification } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import { useStore } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { actionTypes, createFirestoreInstance } from 'redux-firestore';
import { QueryParamProvider } from 'use-query-params';

import { newObject } from 'app/api/utils';
import { IntroProvider } from 'app/components/IntroProvider';
import { PREFIX_CLS, MESSAGE_DURATION } from 'app/constants/antd';
import { Root } from 'app/pages/Root/Loadable';
import {
  customFirestoreActions,
  useCustomFirestoreSlice,
} from 'app/slices/CustomFirestoreSlice';
import { useFirebaseSlice } from 'app/slices/FirebaseSlice';
import { useFirestoreSlice } from 'app/slices/FirestoreSlice';
import { functionsActions, useFunctionsSlice } from 'app/slices/FunctionsSlice';
import 'utils/firebase';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

export function App() {
  const store = useStore();
  useFirebaseSlice();
  useFirestoreSlice();
  useFunctionsSlice();
  useCustomFirestoreSlice();

  // Redux Firebase configuration
  const rrfProps = {
    firebase,
    config: {
      userProfile: 'users',
      enableClaims: true,
      useFirestoreForProfile: true,
      onAuthStateChanged: (authData, firebase, dispatch) => {
        // Clear redux-firestore state if auth does not exist (i.e logout)
        if (!authData) {
          dispatch({ type: actionTypes.CLEAR_DATA });
          dispatch(functionsActions.clearData({}));
          dispatch(customFirestoreActions.clearData({}));
        }
      },
      profileFactory: (userData, profileData, firebase) => {
        const { user } = userData;
        return newObject({
          userId: user.uid,
          email: user.email,
          contacts: [],
          ...profileData,
        });
      },
    },
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  // Configure antd properties
  message.config({
    prefixCls: `${PREFIX_CLS}-message`,
    duration: MESSAGE_DURATION,
  });

  notification.config({
    prefixCls: `${PREFIX_CLS}-notification`,
    duration: MESSAGE_DURATION,
  });

  return (
    <HelmetProvider>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ConfigProvider prefixCls={PREFIX_CLS}>
          <IntroProvider>
            <Elements stripe={stripePromise}>
              <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Switch>
                    <Route exact path="/*" component={Root} />
                  </Switch>
                </QueryParamProvider>
              </BrowserRouter>
            </Elements>
          </IntroProvider>
        </ConfigProvider>
      </ReactReduxFirebaseProvider>
    </HelmetProvider>
  );
}
