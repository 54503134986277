import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { getCurrentUser, updatedObject } from 'app/api/utils';

import { RootState } from 'types/RootState';

import firebase from 'firebase/app';
import 'firebase/auth';

export const useAPIAuthActions = () => {
  const firebase_ = useFirebase();

  return {
    createUser: async (email: string, password: string, profile = {}) => {
      await firebase_.createUser({ email, password }, profile);
      return getCurrentUser().sendEmailVerification();
    },
    login: (email: string, password: string) => {
      return firebase_.login({
        email,
        password,
      });
    },
    resetPassword: (email: string) => {
      return firebase_.resetPassword(email);
    },
    confirmPasswordReset: (code: string, newPassword: string) => {
      return firebase_.confirmPasswordReset(code, newPassword);
    },
    resendEmailVerification: () => {
      // @ts-ignore: Object is possibly 'null'.
      return getCurrentUser().sendEmailVerification();
    },
    verifyEmail: (code: string) => {
      return firebase.auth().applyActionCode(code);
    },
    updateEmail: (newEmail: string) => {
      return firebase_.updateEmail(newEmail, true);
    },
    updatePassword: (newPassword: string) => {
      // @ts-ignore: Object is possibly 'null'.
      return getCurrentUser().currentUser.updatePassword(newPassword);
    },
    updateProfile: profile => {
      return firebase_.updateProfile(updatedObject(profile));
    },
    updateIdToken: () => {
      return firebase_.updateProfile(updatedObject({}));
    },
    logout: () => {
      return firebase_.logout();
    },
  };
};

export const useAPISelectAuth = () => {
  return useSelector((state: RootState) => state.firebase.auth);
};

export const useAPISelectAuthError = () => {
  return useSelector((state: RootState) => state.firebase.authError);
};
