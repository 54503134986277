import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

import { FunctionsState } from './types';

export const initialState: FunctionsState = {
  // @ts-ignore
  data: {},
  // @ts-ignore
  status: { requesting: {} },
};

const slice = createSlice({
  name: 'functions',
  initialState,
  reducers: {
    updateWithFunctionResult(state, action: PayloadAction<any>) {
      return {
        // that has all the existing state data
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.result,
        },
      };
    },
    updateFunctionRequestingStatus(state, action: PayloadAction<any>) {
      return {
        // that has all the existing state data
        ...state,
        status: {
          ...state.status,
          requesting: {
            ...state.status.requesting,
            [action.payload.name]: action.payload.status,
          },
        },
      };
    },
    clearData(state, action: PayloadAction<any>) {
      return initialState;
    },
  },
});

export const { actions: functionsActions } = slice;

export const useFunctionsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
